<template>
  <gf-base>
    <gf-card :tab="tabs" :active-tab.sync="activeTab" @tab-click="tabClick">
      <router-view :active.sync="activeTab"></router-view>
    </gf-card>
  </gf-base>
</template>

<script>
export default {
  data () {
    return {
      activeTab: 1,
      tabs: [
        { id: 1, label: 'Users', routeName: 'users-list' },
        { id: 2, label: 'Groups', routeName: 'groups-list' },
        { id: 3, label: 'Warehouse', routeName: 'warehouse-detail' }
      ]
    }
  },
  methods: {
    tabClick (tab) {
      this.$router.push({ name: this.tabs[tab - 1].routeName })
    }
  }
}
</script>
